
.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    color: var(--header-text);
    font-size: 2rem;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: transform var(--transition-speed);
}

.close-button:hover {
    transform: scale(1.1);
}
  

  
  /* Ensure overlay covers entire screen and closes menu when clicked */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 125;
    cursor: pointer;
}

.side-menu {
    position: fixed;
    top: 0;
    /* Change from left: -var(--menu-width) to left: calc(-1 * var(--menu-width)) for clarity */
    left: calc(-1 * var(--menu-width));
    width: var(--menu-width);
    height: 100%;
    background-color: var(--header-bg);
    padding: 3rem 0 0 0;
    transition: transform var(--transition-speed);
    z-index: 150;
    /* Remove any existing transform */
    transform: translateX(0);
  }
  
  .side-menu.open {
    /* Change from translateX(var(--menu-width)) to translateX(100%) */
    transform: translateX(100%);
  }

.side-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.side-menu li {
    margin: 0;
    padding: 0;
}

.side-menu a {
    display: block;
    color: var(--header-text);
    text-decoration: none;
    padding: 1rem 2rem;
    transition: background-color var(--transition-speed);
}

.side-menu a:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Login link special styling */
.login-link {
    color: var(--accent-color) !important;
    font-weight: bold;
}

@media (max-width: 480px) {
.close-button {
    top: 0.5rem;
    right: 0.5rem;
}
}