.loom-header {
    background: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem 1.5rem;
  }
  
  .header-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .header-left {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
  }
  
  .header-right {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  
  .doc-title {
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  
  .doc-meta {
    color: #666;
    font-size: 0.9rem;
  }
  
  .header-button {
    padding: 0.5rem 1rem;
    border: 1px solid #e0e0e0;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #333;
    transition: all 0.2s ease;
  }
  
  .header-button:hover {
    background: #f5f5f5;
  }
  
  .settings-panel {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e0e0e0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    overflow: hidden;
    transition: all 0.3s ease;
    max-height: 500px;
    opacity: 1;
  }
  
  .settings-panel.collapsed {
    max-height: 0;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    opacity: 0;
  }
  
  .settings-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  
  .settings-label {
    font-size: 0.9rem;
    color: #555;
    white-space: nowrap;
  }