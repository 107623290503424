.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    position: relative;
    display: inline-block;
  }
  
  /* Size variations */
  .loading-spinner.tiny {
    width: 16px;
    height: 16px;
  }

  .loading-spinner.small {
    width: 24px;
    height: 24px;
  }
  
  .loading-spinner.medium {
    width: 40px;
    height: 40px;
  }
  
  .loading-spinner.large {
    width: 56px;
    height: 56px;
  }
  
  .spinner-circle {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-radius: 50%;
    animation: spinner-rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3b82f6 transparent transparent transparent;
  }
  
  .spinner-circle:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .spinner-circle:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .spinner-circle:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
