.templates-sidebar {
  flex: 0 0 300px;
  border-right: 1px solid #e5e5e5;
  padding: 20px;
  overflow-y: auto;
}

.templates-header {
  margin: 0 0 20px 0;
  font-size: 1.5rem;
}

.custom-prompt-item {
  background-color: #f0f9ff;
  border: 1px solid #0369a1;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.2s;
}

.custom-prompt-item:hover {
  background-color: #e0f2fe;
}

.custom-prompt-item.selected {
  background-color: #0369a1;
  color: white;
}

.custom-prompt-item h3 {
  margin: 0 0 8px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.custom-prompt-item p {
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.9;
}

.template-type-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.template-type-button {
  flex: 1;
  padding: 8px 16px;
  border: 1px solid #e5e5e5;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  color: #6b7280;
}

.template-type-button:hover {
  background-color: #f3f4f6;
}

.template-type-button.active {
  background-color: #f0f9ff;
  color: #0369a1;
  border-color: #0369a1;
}

.templates-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.template-item {
  padding: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-item:hover {
  background-color: #f3f4f6;
}

.template-item.selected {
  background-color: #f3f4f6;
  border-color: #0369a1;
}

.template-item h3 {
  margin: 0 0 8px 0;
  font-size: 1rem;
}

.template-preview {
  margin: 0;
  font-size: 0.9rem;
  color: #6b7280;
}