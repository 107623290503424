.transcript-viewer {
  margin: 0 auto;
  padding: 1rem;
}

.transcript-container {
  background: white;
  max-width: 50%;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.transcript-header {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.transcript-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-top: 0;
}
/* 
 */
 /* sidepanel.css */
.content-container {
  display: flex;
  gap: 2rem;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1rem;
}

.transcript-container {
  flex: 1;
  min-width: 0;
}



/*#################################### 
RANGE SELECTOR
####################################*/

.range-selector {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.range-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.range-control label {
  font-weight: bold;
}

.range-control input[type="number"] {
  width: 80px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 32px;
  -moz-appearance: textfield; /* Firefox */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Visibility Dropdown Styles */
.visibility-control {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.visibility-label {
  color: #4b5563;
  font-size: 0.875rem;
}

.visibility-badge {
  background-color: #f3f4f6;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #374151;
  border: 1px solid #e5e7eb;
}

.visibility-badge.clickable {
  cursor: pointer;
  transition: background-color 0.2s;
}

.visibility-badge.clickable:hover {
  background-color: #e5e7eb;
}

.visibility-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #374151;
  font-size: 0.875rem;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
}

.dropdown-item.active {
  background-color: #e5e7eb;
}

.copy-button {
  background-color: #f3f4f6;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #374151;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  margin-top: 10px
}

.copy-button:hover {
  background-color: #e5e7eb;
}


/* 
SKELETON STYLES 
*/
.main-content-loader {
  width: 100%;
  height: calc(100vh - 64px); /* Adjust this based on your nav height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 20px;
  height: 40vh;
  width: 40vw;
  transform: translateY(-20vh);
}

.loader-text {
  font-size: 46px;
  color: #4a5568;
  font-weight: 500;
}

.loader-dots {
  display: flex;
  gap: 4px;
}

.loader-dots span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4a5568;
  opacity: 0.3;
  animation: dots 1.4s infinite;
}

.loader-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots {
  0%, 80%, 100% { 
    opacity: 0.3;
    transform: scale(0.85);
  }
  40% { 
    opacity: 1;
    transform: scale(1);
  }
}