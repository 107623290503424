:root {
  --header-height: 80px;
  --header-bg: #282c34;
  --header-text: white;
  --menu-width: 250px;
  --transition-speed: 0.3s;
  --accent-color: #007bff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative; /* Just for positioning context */
  height: var(--header-height);
  background-color: var(--header-bg);
  color: var(--header-text);
  display: flex;
  align-items: center;
  /* padding: 0 1rem; */
  z-index: 100;
}

/* .App-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background-color: var(--header-bg);
  color: var(--header-text);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: 100;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* General Styles */
.App {
}


/* Content Area */
main {
  width: 100%;
  padding: 0;
  /* background-color: #f1f5f9; */
  box-sizing: border-box; /* Padding is now included in the width */
  font-size: 16px;
}



.title {
  text-align: center;
}

.round-button {
  font-size: 24px;
  margin: 0.5em 1em;
  padding: 0.5em 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.round-button:hover {
  background-color: #0056b3;
}

/* 
.edit-textarea {
  width: 100%;
}
*/


