/* PromptTemplateForm.css */

.template-text {
  line-height: 2.5;
  margin-bottom: 24px;
  background-color: #faefde;  /* Very soft cream color */
  padding: 16px;
  border-radius: 4px;
}

.template-text input,
.template-text .select-input {
  display: inline-block;
  width: 200px;
  margin: 0 4px;
  vertical-align: middle;
}

.prompt-form {
    padding: 20px;
  }
  
  .prompt-form-header {
    margin-bottom: 24px;
  }
  
  .prompt-form-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0 8px 0;
  }
  
  .prompt-form-description {
    font-size: 0.875rem;
    color: #666;
    margin: 0;
  }
  
  .slots-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 24px;
  }
  
  .slot-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .slot-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .text-input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .text-input:focus {
    outline: none;
    border-color: #666;
  }
  
  .select-input {
    position: relative;
    width: 100%;
  }
  
  .select-trigger {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .select-trigger:focus {
    outline: none;
    border-color: #666;
  }
  
  .select-content {
    min-width: 200px;
    width: max-content;  /* Allow dropdown to be wider than input if needed */
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .select-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .select-item:hover {
    background: #f5f5f5;
  }
  
  .error-alert {
    background: #fee2e2;
    border: 1px solid #ef4444;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .error-icon {
    color: #ef4444;
  }
  
  .error-message {
    color: #ef4444;
    font-size: 0.875rem;
    margin: 0;
  }
  
  .submit-button {
    width: auto;
    padding: 10px 16px;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background: #1d4ed8;
  }
  
  .submit-button:disabled {
    background: #93c5fd;
    cursor: not-allowed;
  }