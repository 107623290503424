.transcript-body {
    /* Add any specific container styles for the body */
  }
  
  .transcript-turn {
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  .turn-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    user-select: none;
  }
  
  .speaker-name {
    font-weight: bold;
  }
  
  .timestamp {
    font-size: 0.875rem;
    color: #6b7280;
    cursor: pointer;
  }
  
  .turn-content {
    color: #374151;
    line-height: 1.5;
    user-select: text;
  }
  
  /* Selection and annotation styles */
  .turn-content ::selection {
    background-color: transparent;
  }
  
  .word, .space {
    transition: background-color 0.2s ease;
  }
  
  .space {
    white-space: pre;
  }
  
  .word {
    position: relative;
    border-radius: 2px;
  }
  
  .highlighted-range {
    position: relative;
    background-color: rgb(226, 253, 106);
  }
  
  .annotated-text {
    background-color: lightblue;
  }
  
  /* Annotation modal styles */
  .annotation-modal {
    position: absolute;
    background: white;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
    margin: 0;
    transform: none;
  }
  
  .color-options {
    display: flex;
    gap: 8px;
    padding: 4px;
    user-select: none;
  }
  
  .color-option {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .color-option:hover {
    transform: scale(1.1);
  }

  .edit-speaker-modal {
    background: white;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1001;
    min-width: 280px;
  }
  
  .edit-speaker-buttons {
    display: flex;
    gap: 8px;
    margin-top: 4px;
  }
  
  .edit-speaker-buttons button {
    cursor: pointer;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.2s;
  }
  
  .edit-speaker-buttons button:hover {
    background-color: #0056b3;
  }

  .edit-speaker-modal button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .edit-speaker-modal input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  .edit-speaker-modal input {
    /* width: 100%; */
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .edit-speaker-modal input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }

  .speaker-input-container {
    position: relative;
    width: 100%;
  }

  .speaker-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 2px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1002;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  .suggestion-item {
    padding: 8px;
    cursor: pointer;
    user-select: none;
  }

  .suggestion-item.selected {
    background-color: #e6f3ff;
  }

  .suggestion-item:hover {
    background-color: #f5f5f5;
  }