.discord-button {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #5865F2;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.discord-button:hover {
  background-color: #4752C4;
  transform: translateY(-1px);
}

.discord-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.discord-logo {
  width: 20px;
  height: 20px;
}

.button-text {
  white-space: nowrap;
}

.discord-card {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.discord-card-header {
  background-color: #5865F2;
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.discord-check-circle {
  width: 24px;
  height: 24px;
  color: white;
}

.discord-card-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.discord-card-content {
  padding: 16px;
}

.discord-user-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.discord-username {
  font-size: 16px;
  font-weight: 500;
  color: #2f3136;
}

.discord-meta {
  margin-bottom: 16px;
}

.discord-linked-date {
  font-size: 14px;
  color: #72767d;
}

.discord-unlink-button {
  width: 100%;
  padding: 10px 16px;
  background-color: #ed4245;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.discord-unlink-button:hover {
  background-color: #c03537;
}

.discord-unlink-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.discord-button:disabled .button-text,
.discord-unlink-button:disabled {
  animation: pulse 1.5s infinite;
}