.audio-player {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .audio-player audio {
    width: 100%;
    max-width: 800px;
  }