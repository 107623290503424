/* Documents.css */
.documents-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.documents-container table {
  border: none;
  outline: none;
}

.documents-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.documents-header h2 {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
  font-weight: bold;
}

.new-doc-button {
  background-color: #4a5567;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.new-doc-button:hover {
  background-color: #6e7989;
}

.documents-table-container {
  background: white;
  border: 1px solid #000000;
  border-radius: 8px;
  overflow: hidden;
}

.documents-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.documents-table th {
  text-align: left;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
  color: #666;
  font-weight: 500;
  font-size: 0.8rem;
  background-color: #f9fafb;
}

.documents-table td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e5e7eb;
  font-size: 0.875rem;
  text-align: left;
  user-select: none;  /* Prevents text selection */
  -webkit-user-select: none;  /* For Safari */
}

.documents-table td:last-child {
  text-align: right;
}

.document-name {
  color: #333;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s;
}

.document-name:hover {
  color: #798597;
}

.document-date {
  color: #666;
  font-size: 0.875rem;
}

.document-actions {
  text-align: right;
  padding-right: 1.5rem;
}

.table-delete-button {
  font-size: 0.8rem;
  padding: 0.4rem 0.8rem;
}

.loading-container {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.load-more-container {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #e5e7eb;
}

.load-more-button {
  background: none;
  border: 1px solid #4a5567;
  color: #4a5567;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.load-more-button:hover {
  background-color: #4a5567;
  color: white;
}

/* New styles for deletion state */
.documents-table tr.deleting {
  opacity: 0.5;
  background-color: #f5f5f5;
  transition: opacity 0.2s ease, background-color 0.2s ease;
}

.documents-table tr.deleting .document-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2.3rem;  /* Adjusted to match delete button spacing */
}