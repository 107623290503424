.collapsible-split-panel {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.panel-left {
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.panel-right {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.panel-left-content {
  /* height: 100%; */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  padding: 10px;
}

.panel-left-content.collapsed {
  opacity: 0;
}
  
.panel-divider {
  position: relative;
  width: 2px;
  background-color: #e5e7eb;
  cursor: pointer;
  user-select: none;
}
/* Create a wider invisible area for hover detection */
.panel-divider::before {
  content: '';
  position: absolute;
  top: 0;
  left: -8px;  /* Expand 8px to the left */
  right: -8px; /* Expand 8px to the right */
  height: 100%;
}

.panel-divider:hover {
  background-color: #bfdbfe;
}


.collapse-handle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 48px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.collapse-handle:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.collapse-handle.visible {
  opacity: 1;
}

.collapse-handle.collapsed {
  left: 100%;
}

.chevron-icon {
  color: #4b5563;
  transition: color 0.2s ease-in-out;
}

.collapse-handle:hover .chevron-icon {
  color: #2563eb;
}


/* Remove the original split-view styles since we're replacing them */
.split-view {
  height: 100%;
  position: relative;
}

.left-panel {
  padding: 1rem;
}

.right-panel {
  padding: 1rem;
}