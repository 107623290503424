.credits-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-family: system-ui, -apple-system, sans-serif;
  }
  
  /* Credits Display */
  .credits-display {
    background: linear-gradient(135deg, #2563eb, #1d4ed8);
    border-radius: 16px;
    padding: 2rem;
    color: white;
    text-align: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .credits-label {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    opacity: 0.9;
  }
  
  .credits-amount {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: -0.025em;
  }
  
  /* Purchase History */
  .purchase-history {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  
  .history-title {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .history-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
  }
  
  .history-table th,
  .history-table td {
    padding: 0.75rem;
  }
  
  .history-table th {
    text-align: left;
    color: #4b5563;
    border-bottom: 2px solid #e5e7eb;
  }
  
  .history-table tr {
    border-bottom: 1px solid #e5e7eb;
  }
  
  .amount-cell {
    text-align: right;
  }
  
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .load-more-container {
    text-align: center;
    margin-top: 1rem;
  }
  
  .load-more-button {
    padding: 0.5rem 1rem;
    background: #f3f4f6;
    border: none;
    border-radius: 6px;
    color: #4b5563;
    cursor: pointer;
  }
  
  .load-more-button:hover {
    background: #e5e7eb;
  }
  
  /* Purchase Section */
  .purchase-section {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  
  .purchase-container {
    background: #f9fafb;
    padding: 2rem;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .amount-input-container {
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .input-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
  }
  
  .amount-input {
    font-size: 3rem;
    font-weight: bold;
    width: 200px;
    padding: 0.5rem;
    border: none;
    border-bottom: 3px solid #e5e7eb;
    background: transparent;
    text-align: center;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .amount-input.focused {
    border-color: #2563eb;
  }
  
  .amount-input::-webkit-inner-spin-button {
    opacity: 1;
    height: 48px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .currency-symbol {
    position: absolute;
    left: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #4b5563;
  }
  
  .credits-preview {
    font-size: 1.125rem;
    color: #6b7280;
    margin-bottom: 2rem;
  }
  
  .amount-limits {
    font-size: 0.875rem;
    color: #9ca3af;
    margin-bottom: 1rem;
  }
  
  .purchase-button {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    background: linear-gradient(135deg, #2563eb, #1d4ed8);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .purchase-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
  }
  
  .error-message {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 1rem;
  }
  
  /* Alpha Notice */
  .alpha-notice {
    text-align: center;
    font-size: 0.875rem;
    color: #6b7280;
    padding: 1rem;
  }