.editable-wrapper {
    /* Container to handle the error positioning context */
    position: relative;
    display: inline-block;
  }
  
  .editable-container {
    display: inline-block;
    padding: 0.25rem;
    margin: -0.25rem;
    border-radius: 4px;
    cursor: text;
  }
  
  .editable-container:hover {
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .editable-input {
    font-size: inherit;
    font-weight: inherit;
    width: 100%;
    min-width: 60px;
    border: 1px solid #0066cc;
    border-radius: 4px;
    padding: 0.25rem;
    outline: none;
    font-family: inherit;
  }
  
  .validation-error {
    position: fixed; /* Changed to fixed positioning */
    font-size: 0.75rem;
    color: #dc2626;
    background: white; /* Added background */
    padding: 2px 4px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    animation: fadeOut 2s forwards;
    pointer-events: none;
    white-space: nowrap;
    z-index: 1000;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; }
  }