
.main-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 2rem;
}
  
  .main-nav a {
    color: var(--header-text);
    text-decoration: none;
    font-size: 1.8em;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color var(--transition-speed);
  }
  
  .main-nav a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }