/* Hamburger button styles */
.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    left: 1rem; /* Adjust based on your logo width */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  
  .hamburger span {
    width: 2rem;
    height: 0.25rem;
    background: var(--header-text);
    border-radius: 10px;
    transition: all var(--transition-speed) ease-in-out;
    position: relative;
    transform-origin: 1px;
  }
  
  .hamburger.open span:first-child {
    transform: rotate(45deg);
  }
  
  .hamburger.open span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
  }