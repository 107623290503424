.logo-image-container {
    font-size: 1.5rem;
    font-weight: bold;
  }

.logo-image {
    height: calc(var(--header-height));
}

.logo {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    color: var(--header-text);
    z-index: 1;
}