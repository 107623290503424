/* Conversations.css */
.conversations-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.conversations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.conversations-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

td {
  text-align: left;
}

.add-conversation-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.add-conversation-button:hover {
  background-color: #2563eb;
}

.conversations-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
}

.conversations-table th {
  background-color: #f8fafc;
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #475569;
  font-size: 0.875rem;
  border-bottom: 1px solid #e2e8f0;
}

.conversations-table td {
  padding: 1rem;
  color: #1e293b;
  border-bottom: 1px solid #e2e8f0;
  background-color: white;
}

.conversations-table tr:last-child td {
  border-bottom: none;
}

.conversation-name-cell {
  font-weight: bold;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-badge.completed {
  background-color: #dcfce7;
  color: #166534;
}

.status-badge.processing,
.status-badge.pending {
  background-color: #e0f2fe;
  color: #075985;
}

.status-badge.failed {
  background-color: #fee2e2;
  color: #991b1b;
}

.meta-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.delete-button {
  background: none;
  border: none;
  color: #ef4444;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #fee2e2;
}

.add-conversation-button:disabled {
  background-color: #94a3b8;
  cursor: not-allowed;
}

.add-conversation-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}