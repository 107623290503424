.confirmation-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-dialog {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 24rem;
    width: 90%;
  }
  
  .confirmation-dialog-title {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
  }
  
  .confirmation-dialog-message {
    margin: 0 0 1.5rem 0;
    color: #4b5563;
  }
  
  .confirmation-dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
  }
  
  .confirmation-dialog-button-cancel,
  .confirmation-dialog-button-confirm {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .confirmation-dialog-button-cancel {
    background-color: #f3f4f6;
    border: 1px solid #e5e7eb;
    color: #374151;
  }
  
  .confirmation-dialog-button-cancel:hover {
    background-color: #e5e7eb;
  }
  
  .confirmation-dialog-button-confirm {
    background-color: #2563eb;
    border: 1px solid #2563eb;
    color: white;
  }
  
  .confirmation-dialog-button-confirm:hover {
    background-color: #1d4ed8;
  }