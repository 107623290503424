.dropdown-container {
    position: relative;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }
  
  .dropdown-button {
    width: 100%;
    padding: 8px 16px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .dropdown-button:hover:not(:disabled) {
    background: #f8fafc;
    border-color: #cbd5e1;
  }
  
  .dropdown-button:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
  
  .dropdown-button:disabled {
    background: #f1f5f9;
    cursor: not-allowed;
    color: #94a3b8;
  }
  
  .dropdown-button-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .dropdown-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #64748b;
    transition: transform 0.2s ease;
  }
  
  .dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  .dropdown-menu {
    position: fixed;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    max-height: 250px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .dropdown-menu::-webkit-scrollbar {
    width: 8px;
  }
  
  .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f5f9;
  }
  
  .dropdown-menu::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }
  
  .dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .dropdown-item:hover:not(.disabled) {
    background: #eff6ff;
  }
  
  .dropdown-item.selected {
    background: #dbeafe;
  }
  
  .dropdown-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .dropdown-placeholder {
    color: #94a3b8;
  }
  
  .dropdown-no-options {
    padding: 8px 16px;
    color: #94a3b8;
    font-style: italic;
  }