/* annotation-panel.css */
.annotation-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .annotation-breadcrumb {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .breadcrumb-item {
    font-size: 0.875rem;
    color: #6b7280;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .breadcrumb-item:hover {
    color: #2563eb;
  }
  
  .breadcrumb-item.current {
    color: #111827;
    cursor: default;
  }
  
  .breadcrumb-separator {
    color: #9ca3af;
  }
    
  /* Update panel-content to support our new collapsible layout */
  .panel-content {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  
  /* Transition styles for smooth animations */
  .panel-transition {
    transition: all 0.3s ease-in-out;
  }
  
  /* Groups List */
  .groups-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .group-item {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .group-item:hover {
    border-color: #2563eb;
    background-color: #f8fafc;
  }
  
  .group-meta {
    display: flex;
    gap: 1rem;
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 0.5rem;
  }
  
  .group-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    background-color: #f3f4f6;
    border-radius: 9999px;
    color: #4b5563;
  }
  
  /* Filter Panel */
  .filter-panel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .filter-section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .filter-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  /* Annotations List */
  .annotations-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .annotation-item {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .annotation-item:hover {
    border-color: #2563eb;
    background-color: #f8fafc;
  }
  
  .annotation-preview {
    font-size: 0.875rem;
    color: #111827;
    margin-bottom: 0.5rem;
  }
  
  .annotation-meta {
    font-size: 0.75rem;
    color: #6b7280;
  }

.group-detail-view {
  padding: 10px;
}

  .group-item-header,
.annotation-item-header,
.group-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.group-item-header h4,
.annotation-preview {
  flex: 1;
  cursor: pointer;
}

.group-detail-header h3 {
  margin: 0;
}

/* Make sure delete buttons don't get too big */
.group-delete-button,
.annotation-delete-button {
  flex-shrink: 0;
}

/* WOrking group indicators */
/* Add to your annotation-panel.css file */
.working-group-indicator {
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.working-group-button {
  background: none;
  border: none;
  color: #2563eb;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
}

.working-group-button:hover {
  text-decoration: underline;
}

.group-detail-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.working-group-toggle {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  background-color: white;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s ease;
}

.working-group-toggle:hover {
  border-color: #2563eb;
  color: #2563eb;
}

.working-group-toggle.active {
  background-color: #2563eb;
  color: white;
  border-color: #2563eb;
}

.working-group-toggle.active:hover {
  background-color: #1d4ed8;
  border-color: #1d4ed8;
}