.query-panel {
    height: 100%;
    overflow-y: auto;
  }
  
  .query-panel-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* System Prompt Section */
  .system-prompt-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .system-prompt-toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    font-size: 0.875rem;
    color: #374151;
    cursor: pointer;
    padding: 0.25rem 0;
  }
  
  .toggle-icon {
    font-size: 0.75rem;
    line-height: 1;
  }
  
  .system-prompt-content {
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #374151;
    white-space: pre-wrap;
  }
  
  /* Model Selector */
  .model-selector {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .model-selector label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  /* Example Selector */
  .example-selector {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .example-selector h3 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
    margin: 0;
  }
  
  .example-groups {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .example-group-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #374151;
    cursor: pointer;
  }
  
  .example-group-item input[type="checkbox"] {
    margin: 0;
  }
  
  /* Prompt Input Section */
  .prompt-input-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .prompt-input-section label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  .prompt-textarea {
    min-height: 200px;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    resize: vertical;
    width: 100%;
  }
  
  .prompt-textarea:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }
  
  /* Query Button */
  .query-button {
    padding: 0.75rem 1rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .query-button:hover:not(:disabled) {
    background-color: #1d4ed8;
  }
  
  .query-button:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
  
  /* Loading State */
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .loader {
    width: 1rem;
    height: 1rem;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }