.page-container {
    min-height: calc(100vh - var(--header-height));
    background-image: url('../../public/maze-min.jpeg');
    display: flex;
    justify-content: center;
    padding: 0 20px;
    background-size: cover;
  }
  
  .content-card {
    background-color: rgb(243 249 255 / 85%);
    margin-top: 2rem;
    max-width: 42rem;
    width: 100%;
    height: min-content;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .content-card h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #1a2236;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }

  .content-text {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #334155;
    /* text-align: center; */
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .content-card {
      padding: 1.5rem;
    }
    
    .content-card h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }