/* AnnotationPromptDebug.css */
.debug-view {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .debug-section {
    background: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
  }
  
  .debug-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  /* Metadata Grid */
  .debug-metadata {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .metadata-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .metadata-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .metadata-content {
    font-size: 0.875rem;
    background: #f9fafb;
    padding: 0.5rem;
    border-radius: 0.375rem;
  }
  
  /* Expandable Text */
  .expandable-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .expandable-text-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #4b5563;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .expandable-text-button:hover {
    color: #2563eb;
  }
  
  .expandable-text-content {
    white-space: pre-wrap;
    background: #f9fafb;
    padding: 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  /* Results Section */
  .results-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .results-summary {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .results-toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #4b5563;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .results-toggle:hover {
    color: #2563eb;
  }
  
  .results-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Result Item */
  .result-item {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    padding: 1rem;
  }
  
  .result-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: left;
  }
  
  .result-item-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }
  
  .result-item-count {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .result-section {
    gap: 0.25rem;  /* Reduced gap since expandable text has its own padding */
  }
  
  .result-item-content {
    padding: 0.5rem;  /* Add some padding to the content container */
  }
  
  .result-section-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .result-section-content {
    white-space: pre-wrap;
    background: #f9fafb;
    padding: 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  /* Result Units */
  .result-units {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .result-unit-label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  .result-unit-content {
    font-size: 0.875rem;
    background: #f9fafb;
    padding: 0.5rem;
    border-radius: 0.375rem;
  }
  
  /* Result Error */
  .result-error {
    color: #dc2626;
  }
  
  .result-error-label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  .result-error-content {
    white-space: pre-wrap;
    background: #fef2f2;
    padding: 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  /* Annotation IDs */
  .result-annotations-label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .result-annotations-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .annotation-id {
    font-size: 0.75rem;
    background: #eff6ff;
    color: #2563eb;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
  }
  
  /* Chevron Icon */
  .chevron-icon {
    transition: transform 0.2s ease;
  }
  
  .chevron-icon.expanded {
    transform: rotate(90deg);
  }

  .result-expandable {
    width: 100%;
  }
  
  .result-expandable .expandable-text-button {
    width: 100%;
    padding: 0.5rem;
    background: #f9fafb;
    border-radius: 0.375rem;
    justify-content: flex-start;
  }
  
  .result-expandable .expandable-text-button:hover {
    background: #f3f4f6;
  }
  
  .result-expandable .expandable-text-content {
    margin-top: 0.5rem;
    border: 1px solid #e5e7eb;
  }
  /* 
   */
   /*  */
   /* Modal backdrop */
.comparison-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.comparison-modal {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Modal header */
.comparison-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.comparison-modal-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #111827;
  margin: 0;
}

.comparison-modal-close {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: #6b7280;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparison-modal-close:hover {
  color: #111827;
  background-color: #f3f4f6;
}

/* Error banner styles */
.comparison-error-banner {
  background-color: #fee2e2;
  color: #991b1b;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  border: 1px solid #fecaca;
}

/* Text highlighting styles */
.highlight-success {
  background-color: rgba(34, 197, 94, 0.1);
  color: inherit;
}

.highlight-error {
  background-color: rgba(239, 68, 68, 0.1);
  color: inherit;
}

/* Adjust modal content for error banner */
.comparison-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  height: calc(100% - 4rem);
  overflow: hidden;
}

.comparison-panels-container {
  display: flex;
  gap: 1rem;
  flex: 1;
  overflow: hidden;
}

/* Text panels */
.comparison-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f9fafb;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  overflow: hidden;
}

.comparison-panel-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0.75rem 1rem;
  background: white;
  border-bottom: 1px solid #e5e7eb;
}

.comparison-panel-content {
  flex: 1;
  margin: 0;
  padding: 1rem;
  overflow-y: auto;
  font-size: 0.875rem;
  line-height: 1.5;
  white-space: pre-wrap;
}

  /* Maximize button styles */
  .result-item-controls {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .result-item-header {
    flex: 1;
  }
  
  .maximize-button {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    color: #6b7280;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .maximize-button:hover {
    color: #111827;
    background-color: #f3f4f6;
  }