.side-panel {
    width: 50%;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 2rem);
    position: sticky;
    top: 1rem;
  }
  
  .tabs-container {
    display: flex;
    border-bottom: 1px solid #e5e7eb;
    padding: 0 1rem;
  }
  
  .tab {
    padding: 1rem 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    color: #6b7280;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease;
  }
  
  .tab:hover {
    color: #111827;
  }
  
  .tab.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
  }
  
  .panel-content {
    height: calc(100% - 3.25rem);
    overflow-y: auto;
  }
  
  .chat-panel, .annotations-panel {
    height: 100%;
  }