
.loom-container {
  max-width: 75%;
  margin: auto;
}

.parent-container {
  display: flex;
  height: 100vh; /* Full height of the parent container */
}

.sidebar {
  width: 33%;
  height: 100%; /* Full height of the parent container */
  background-color: #f1f1f1; /* Light background color */
  padding: 1em 0em 1em 1em;
  overflow-y: auto; /* Scrollable content */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow on the left side */
  order: 2; /* Ensure the sidebar is positioned last in the flexbox (right side) */
}

.loom-body {
  flex: 1; /* Take up the remaining space on the left */
  padding: 1em 1em 1em 1em;
  order: 1; /* Main content comes before the sidebar in flex order */
}

.sidebar-content {
  margin-top: 20px;
  overflow-y: auto;
}

.generator-indicator {
  font-size: 0.8rem;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

table {
  border-collapse: collapse; /* Merge borders into a single grid */
  border: 1px solid black; /* Outer border of the table */
}

td {
  min-width: 1.5em;
  min-height: 1.2em;
  text-align: center;
}

.loom-spinner {
  padding: 0em;
}

.prompt-intput {
  width: 100%;
}

.prompt-input textarea {
  width: 100%;
  min-height: 400px;

}

.prompt-input-container {
  padding: 20px;
  width: 100%;
}

.prompt-selection-container {
  display: flex;
  flex-direction: row;
}

.submit-prompt {
  width: auto;
  padding: 10px 16px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.settings-item .dropdown-container {
  width: 200px;  /* or whatever width you prefer */
  display: inline-block;
  vertical-align: middle;
}

.settings-item .dropdown-button {
  width: 100%;
  text-align: left;
  padding: 4px 8px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}
