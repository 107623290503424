/* Annotation Detail View Styles */
.annotation-detail-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent double scrollbars */
}

.annotation-scroll-button {
  align-self: flex-start;
  margin: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  color: #374151;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.annotation-scroll-button:hover {
  background-color: #e5e7eb;
  border-color: #d1d5db;
}

.annotation-detail.split-view {
    height: calc(100% - 4rem);
    display: flex;
  }
  
  .annotation-detail .left-panel,
  .annotation-detail .right-panel {
    flex: 1;
    padding: 1.5rem;
    overflow-y: auto; /* Keep scrolling within panels */
    /* height: 100%; */
  }
  
  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .panel-header h3 {
    margin: 0;
  }
  
  /* Content Panel */
  .content-pairs-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .content-pair {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #f9fafb;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .input-label {
    font-size: 0.75rem;
    color: #6b7280;
    font-weight: 500;
  }
  
  .content-input {
    width: auto;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  .content-input.key-input {
    background-color: #fff;
    border-left: 3px solid #3b82f6;  /* blue accent for key */
  }
  
  .content-input.value-input {
    background-color: #fff;
    border-left: 3px solid #10b981;  /* green accent for value */
  }
  
  .content-input:focus {
    outline: none;
    ring: 2px solid rgba(37, 99, 235, 0.2);
  }
  
  .content-pair-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }
  
  .delete-pair-button {
    padding: 0.25rem 0.75rem;
    background-color: #fee2e2;
    border: none;
    border-radius: 0.375rem;
    color: #dc2626;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .delete-pair-button:hover {
    background-color: #fecaca;
  }
  
  .add-content-button {
    padding: 0.5rem 1rem;
    background-color: #f3f4f6;
    border: none;
    border-radius: 0.375rem;
    color: #374151;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .add-content-button:hover {
    background-color: #e5e7eb;
  }
  
  .update-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #2563eb;
    border: none;
    border-radius: 0.375rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .update-button:hover {
    background-color: #1d4ed8;
  }
  
  /* Metadata Panel */
  .metadata-list {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  
  .metadata-field {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .metadata-label {
    font-size: 0.75rem;
    color: #6b7280;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .metadata-value {
    font-size: 0.875rem;
    color: #111827;
  }
  
  .metadata-section {
    border-top: 1px solid #e5e7eb;
    padding-top: 1rem;
  }
  
  .metadata-section h4 {
    margin: 0 0 0.75rem 0;
    color: #374151;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  .tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .no-tags {
    color: #6b7280;
    font-size: 0.875rem;
    font-style: italic;
  }
  
  .empty-state {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
    font-size: 0.875rem;
    background-color: #f9fafb;
    border-radius: 0.375rem;
    border: 1px dashed #e5e7eb;
  }

  /*  */

  .delete-annotation-button {
    padding: 0.5rem 1rem;
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 0.375rem;
    color: #dc2626;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .delete-annotation-button:hover {
    background-color: #fecaca;
    border-color: #f87171;
  }
  
  .delete-annotation-button:active {
    background-color: #fca5a5;
  }