.delete-button {
    padding: 0.25rem 0.5rem;
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    color: #dc2626;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .delete-button:hover {
    background-color: #fecaca;
    border-color: #ef4444;
  }