.template-manager {
    display: flex;
    height: 100%;
    gap: 24px;
  }
  
  .template-list {
    flex: 0 0 300px;
    border-right: 1px solid #e5e5e5;
    padding: 20px;
  }
  
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .list-header h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .new-template-button {
    padding: 8px 16px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .new-template-button:hover {
    background-color: #1d4ed8;
  }
  
  .templates {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .template-item {
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .template-item:hover {
    background-color: #f5f5f5;
  }
  
  .template-item.selected {
    border-color: #2563eb;
    background-color: #f0f5ff;
  }
  
  .template-item h3 {
    margin: 0 0 8px 0;
    font-size: 1rem;
  }
  
  .template-preview {
    margin: 0;
    font-size: 0.875rem;
    color: #666;
  }
  
  .template-sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }
  
  .sidebar-header {
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .tab-buttons {
    display: flex;
    gap: 8px;
  }
  
  .tab-button {
    padding: 8px 16px;
    border: 1px solid #e5e5e5;
    background: none;
    border-radius: 4px;
    cursor: pointer;
    color: #6b7280;  /* Medium gray for inactive */
  }

  .tab-button:hover {
    background-color: #f3f4f6;  /* Light gray hover */
  }
  
  .tab-button.active {
    background-color: #f0f9ff;  /* Very light blue background */
    color: #0369a1;  /* Darker blue text */
    border-color: #0369a1;
  }
  
  .sidebar-content {
    flex: 1;
    padding: 20px;
    overflow: auto;
  }
  
  .template-editor {
    width: 100%;
    height: 300px;
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-family: monospace;
    resize: vertical;
    margin-bottom: 16px;
  }
  
  .save-button {
    padding: 8px 16px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #1d4ed8;
  }

  .delete-button {
    padding: 8px 16px;
    background-color: #dc2626;  /* bright red */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #b91c1c;  /* darker red */
  }
  
  .preview-mode {
    height: 100%;
    overflow: auto;
  }

  .template-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .template-name {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .template-name-input {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 4px 8px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    width: 300px;
  }
  
  .template-name-input:focus {
    outline: none;
    border-color: #0369a1;
  }
  
  .visibility-toggle {
    padding: 6px 12px;
    border-radius: 9999px;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s;
  }
  
  .visibility-toggle.private {
    background-color: #f3f4f6;
    color: #6b7280;
    border-color: #e5e5e5;
  }
  
  .visibility-toggle.private:hover {
    background-color: #e5e7eb;
  }
  
  .visibility-toggle.public {
    background-color: #ecfdf5;
    color: #059669;
    border-color: #059669;
  }
  
  .visibility-toggle.public:hover {
    background-color: #d1fae5;
  }

  .template-keyword {
    padding: 4px 8px;
    background-color: #cbfbea;
    border-radius: 4px;
    margin-bottom: 8px;
    display: inline-block;
    cursor: pointer;
  }